import { Injectable } from '@angular/core';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject, Observable } from 'rxjs';

import { Breadcrumb } from '../interfaces';

@Injectable({
	providedIn: 'root'
})
export class BreadcrumbService {
	private readonly breadcrumbsSubject$: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject([]);
	private readonly homeBreadcrumb: Breadcrumb = {
		title: 'Home',
		routePath: ['/', this.i18nService.currentLanguage],
		allowNav: true
	};
	private readonly breadcrumbPositionSubject$: BehaviorSubject<'static' | 'absolute'> = new BehaviorSubject<
		'static' | 'absolute'
	>('static');

	/**
	 * An array of breadcrumbs for the entire application
	 */
	public readonly breadcrumbs$: ObservableArray<Breadcrumb> = this.breadcrumbsSubject$.asObservable();
	/**
	 *
	 */
	public readonly position$: Observable<'static' | 'absolute'> = this.breadcrumbPositionSubject$.asObservable();

	constructor(private readonly i18nService: NgxI18nService) {}

	/**
	 * Set the breadcrumbs that need to be displayed in the breadcrumb component
	 *
	 * @param breadcrumbs - A series of breadcrumbs we wish to display in the breadcrumb component
	 * @param prefixHome - Whether we want the breadcrumbs to be prefixed with a Home crumb. By default, true.
	 * @param position - The position of the breadcrumb component. By default, 'static'.
	 */
	public setBreadcrumbs(
		breadcrumbs: Breadcrumb[],
		prefixHome = true,
		position: 'static' | 'absolute' = 'static'
	): void {
		this.breadcrumbsSubject$.next([
			...(prefixHome ? [this.homeBreadcrumb] : []),
			...[...breadcrumbs].map((breadcrumb) => {
				// Iben: If we don't want to add the language to the routePath we return the breadcrumb
				if (breadcrumb.withoutLanguage) {
					return breadcrumb;
				}

				// Iben: Add the current language to the route path
				return {
					...breadcrumb,
					routePath: ['/', this.i18nService.currentLanguage, ...breadcrumb.routePath]
				};
			})
		]);
		this.breadcrumbPositionSubject$.next(position);
	}

	/**
	 * Empties the breadcrumbs for the breadcrumb component
	 */
	public clearBreadcrumbs(): void {
		this.breadcrumbsSubject$.next([]);
		this.breadcrumbPositionSubject$.next('static');
	}
}
